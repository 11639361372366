import React from 'react';

import Icon from './Icon';

export default function KeyboardIcon({ className = '', text = 'Keyboard' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10C7 10.5523 6.55228 11 6 11C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9C6.55228 9 7 9.44771 7 10Z" fill="currentColor" />
      <path d="M18 11C18.5523 11 19 10.5523 19 10C19 9.44771 18.5523 9 18 9C17.4477 9 17 9.44771 17 10C17 10.5523 17.4477 11 18 11Z" fill="currentColor" />
      <path d="M10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15H14C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13H10Z" fill="currentColor" />
      <path d="M15 10C15 10.5523 14.5523 11 14 11C13.4477 11 13 10.5523 13 10C13 9.44771 13.4477 9 14 9C14.5523 9 15 9.44771 15 10Z" fill="currentColor" />
      <path d="M10 11C10.5523 11 11 10.5523 11 10C11 9.44771 10.5523 9 10 9C9.44771 9 9 9.44771 9 10C9 10.5523 9.44771 11 10 11Z" fill="currentColor" />
      <path d="M6 15C6.55228 15 7 14.5523 7 14C7 13.4477 6.55228 13 6 13C5.44772 13 5 13.4477 5 14C5 14.5523 5.44772 15 6 15Z" fill="currentColor" />
      <path d="M19 14C19 14.5523 18.5523 15 18 15C17.4477 15 17 14.5523 17 14C17 13.4477 17.4477 13 18 13C18.5523 13 19 13.4477 19 14Z" fill="currentColor" />
      <path d="M4.82528 4.25C2.87719 4.25 1.25 5.7759 1.25 7.7195V16.2805C1.25 18.2243 2.87861 19.75 4.82528 19.75H19.1735C21.1215 19.75 22.7484 18.2237 22.75 16.2817V7.7195C22.75 5.7757 21.1214 4.25 19.1747 4.25H4.82528ZM2.75 7.7195C2.75 6.65777 3.65115 5.75 4.82528 5.75H19.1747C20.3479 5.75 21.25 6.65797 21.25 7.7195V16.2811C21.2491 17.342 20.3477 18.25 19.1735 18.25H4.82528C3.65215 18.25 2.75 17.342 2.75 16.2805V7.7195Z" fill="currentColor" />
    </svg>
  </Icon>;
}

