import React from 'react';

import Icon from './Icon';

export default function HelpIcon({ className = '', text = 'Help' }: { className?: string; text?: string; }): JSX.Element {
  return <Icon text={text}>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.24927 9.99917C9.24927 8.47993 10.4809 7.24834 12.0001 7.24834C13.5193 7.24834 14.7509 8.47993 14.7509 9.99917C14.7509 11.2584 13.9048 12.32 12.7501 12.6465V13.5006C12.7501 13.9148 12.4143 14.2506 12.0001 14.2506C11.5859 14.2506 11.2501 13.9148 11.2501 13.5006V12C11.2501 11.5858 11.5859 11.25 12.0001 11.25C12.6909 11.25 13.2509 10.69 13.2509 9.99917C13.2509 9.30835 12.6909 8.74834 12.0001 8.74834C11.3093 8.74834 10.7493 9.30835 10.7493 9.99917C10.7493 10.4134 10.4135 10.7492 9.99927 10.7492C9.58505 10.7492 9.24927 10.4134 9.24927 9.99917Z" fill="currentColor" />
      <path d="M11.2002 16.0017C11.2002 15.5598 11.5584 15.2016 12.0002 15.2016C12.4421 15.2016 12.8002 15.5598 12.8002 16.0017C12.8002 16.4435 12.4421 16.8017 12.0002 16.8017C11.5584 16.8017 11.2002 16.4435 11.2002 16.0017Z" fill="currentColor" />
      <path d="M12.0001 2.24625C6.61324 2.24625 2.24634 6.61316 2.24634 12C2.24634 17.3869 6.61324 21.7538 12.0001 21.7538C17.3869 21.7538 21.7538 17.3869 21.7538 12C21.7538 6.61316 17.3869 2.24625 12.0001 2.24625ZM3.74634 12C3.74634 7.44158 7.44167 3.74625 12.0001 3.74625C16.5585 3.74625 20.2538 7.44158 20.2538 12C20.2538 16.5584 16.5585 20.2538 12.0001 20.2538C7.44167 20.2538 3.74634 16.5584 3.74634 12Z" fill="currentColor" />
    </svg>
  </Icon>;
}

